import * as React from "react";
import { ContextBar, Header, Logo, ToastNotification, ToastNotifications } from "@vwfs-bronson/bronson-react";
import LogoDesktop from "./images/vwfs-default-logo_desktop.svg";
import LogoMobile from "./images/vwfs-default-logo_mobile.svg";
import "./PageHeader.css";
import { getKeycloakInitialized, getKeycloakIsLogged } from "@services/redux/features/keycloak.redux";
import { connect } from "react-redux";
import { logOut } from "@services/api/login";
import { useTranslation } from "react-i18next";
import useKeycloakConditonal from "@common/hooks/useKeycloakConditional";
import { redirectTo } from "@common/utils";

export interface PageHeaderProps {
  documentTitle?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

export interface PageHeaderReduxProps extends PageHeaderProps {
  isLoggedIn: boolean;
  keycloakInitialized: boolean;
}

const PageHeader = (props: PageHeaderReduxProps) => {
  const { t } = useTranslation();
  const [logoutSuccessful, setLogoutSuccessful] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const { documentTitle, isLoggedIn, keycloakInitialized, title } = props;
  const { keycloak } = useKeycloakConditonal(keycloakInitialized);
  const docTitle = documentTitle ? documentTitle : title;

  React.useEffect(() => {
    document.title = `${docTitle || ""}`;
  }, [docTitle]);

  let removeNotification: any;
  const handleShowNotification = (value: boolean) => {
    setLogoutSuccessful(value);
    setShowNotification(true);
    removeNotification = setTimeout(() => setShowNotification(false), 5000);
  };

  const setLoggedOut = async (e: any) => {
    e.preventDefault();
    if (keycloak) {
      try {
        await logOut({
          token: keycloak.token,
          clientId: keycloak.clientId,
          clientSecret: keycloak.clientSecret,
          refreshToken: keycloak.refreshToken,
        });
        keycloak?.clearToken();
        handleShowNotification(true);
      } catch (error) {
        handleShowNotification(false);
      }
    }
  };

  return (
    <>
      {showNotification && (
        <ToastNotifications>
          <ToastNotification
            onClose={() => {
              setShowNotification(false);
              clearTimeout(removeNotification);
            }}
            {...(logoutSuccessful ? { success: true } : { error: true })}
          >
            {t("login:logoutSuccess")}
          </ToastNotification>
        </ToastNotifications>
      )}
      <Header
        testId="appHeader"
        staticHeader
        logo={
          <div style={{ display: "flex", maxHeight: "70px" }}>
            <Logo alt={t("login:logoAlt")} testId="appHeaderLogo" aria-label="Zur Startseite" src={LogoDesktop} srcSmall={LogoMobile} onClick={() => redirectTo("https://www.vwfs.de/")} />
          </div>
        }
      >
        <ContextBar isContextBar>
          {(title || isLoggedIn) && (
            <ContextBar.SiteNav>
              <ContextBar.SiteNav.Item title noLink label={title as any}></ContextBar.SiteNav.Item>
            </ContextBar.SiteNav>
          )}
          {(title || isLoggedIn) && (
            <ContextBar.SiteNav>
              <ContextBar.SiteNav.Item noLink>
                {isLoggedIn && (
                  <a className="c-context-bar__link" href="#/" onClick={setLoggedOut} rel="noopener noreferrer">
                    <span>{t("login:logout")}</span>
                  </a>
                )}
              </ContextBar.SiteNav.Item>
            </ContextBar.SiteNav>
          )}
        </ContextBar>
      </Header>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: getKeycloakIsLogged(state),
    keycloakInitialized: getKeycloakInitialized(state),
  };
};
export default connect<{}, {}, PageHeaderProps>(mapStateToProps)(PageHeader);
