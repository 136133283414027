import { ContentSection, Main } from "@vwfs-bronson/bronson-react";
import PageHeader, { PageHeaderProps } from "./PageHeader";
import PageFooter from "./PageFooter";
import { SkipLink } from "./SkipLink";

interface Props extends PageHeaderProps {
  pageWrapSize?: "medium" | "small" | "xsmall" | "xxsmall"; // Defaults to xsmall
  contentSectionClassName?: string; // Defaults to u-pt
  children?: React.ReactNode;
}

export const CommonPage: React.FC<Props> = ({ children, pageWrapSize, contentSectionClassName, ...pageHeaderProps }) => (
  <>
    <SkipLink id="main" />
    <PageHeader {...pageHeaderProps} />

    <Main id="main">
      <ContentSection pageWrapSize={pageWrapSize ?? "xsmall"} className={contentSectionClassName ?? "u-pt"}>
        <ContentSection.ComponentWrapper>{children}</ContentSection.ComponentWrapper>
      </ContentSection>
    </Main>

    <PageFooter />
  </>
);
