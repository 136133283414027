import { useEffect } from "react";
import { Button, ButtonContainer, Heading, Paragraph } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";
import { getStorefrontData } from "@services/redux/features/storefront.redux";
import { connect } from "react-redux";
import { trackPage } from "./tracking";

export interface ErrorBasePageProps {
  onClick?: Function;
}

interface ErrorBasePagePropsRedux extends ErrorBasePageProps {
  storefrontData: any;
}

const ErrorBasePage = ({ onClick, storefrontData }: ErrorBasePagePropsRedux) => {
  const { t } = useTranslation();
  const texts = t("error:errorOption:texts", { returnObjects: true }) as Array<string>;

  useEffect(() => {
    trackPage();
  }, []);

  return (
    <CommonProductPage>
      <Heading level={1} className="u-mb-large">
        {t("error:errorOption:title")}
      </Heading>
      {texts.map((text: string) => (
        <Paragraph>{text}</Paragraph>
      ))}
      <p
        dangerouslySetInnerHTML={{
          __html: t("error:errorOption:phoneText", { processID: storefrontData?.transaction?.acpProcessId }),
        }}
      ></p>
      {onClick && (
        <ButtonContainer center className="u-mt-xlarge">
          <Button type="button" onClick={() => onClick}>
            {t("error:errorOption:button")}
          </Button>
        </ButtonContainer>
      )}
    </CommonProductPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    storefrontData: getStorefrontData(state),
  };
};
export default connect<{}, {}, ErrorBasePageProps>(mapStateToProps, {})(ErrorBasePage);
