import { useState } from "react";

interface Props {
  id: string;
}

export const SkipLink = ({ id }: Props) => {
  const [visible, setVisible] = useState(false);

  const handleSkipLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const mainContent = document.getElementById(id);
    if (mainContent) {
      mainContent.scrollIntoView({ behavior: "smooth", block: "start" });
      mainContent.setAttribute("tabindex", "-1");
      mainContent.focus();
      mainContent.removeAttribute("tabindex");
    }
  };

  return (
    <a
      href={`#${id}`}
      id="skipLink"
      onClick={handleSkipLink}
      onFocus={() => setVisible(true)}
      onBlur={() => setVisible(false)}
      className={visible ? "u-text-center" : "u-visually-hidden"}
    >
      Zum Hauptinhalt springen
    </a>
  );
};