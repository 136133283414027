import { useEffect } from "react";
import { Button, ButtonContainer, Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";
import { store } from "@services/redux";
import * as customFormActions from "../../../services/redux/features/custom.redux";
import { trackPage } from "./tracking";

export const ErrorDocumentUploadPage = () => {
  const { t } = useTranslation();
  const onClick = () => store.dispatch(customFormActions.setShowContinueLater(true));

  useEffect(() => {
    trackPage();
  }, []);

  return (
    <CommonProductPage>
      <SectionHeading center level={3} smallSpacing={true} className="u-mb-large">
        {t("error:errorDocumentUpload:title")}
      </SectionHeading>
      <Paragraph className="u-text-center">{t("error:errorDocumentUpload:text1")}</Paragraph>
      <Paragraph className="u-text-center">{t("error:errorDocumentUpload:text2")}</Paragraph>
      <ButtonContainer center className="u-mt-xlarge" onClick={onClick}>
        <Button type="button">{t("error:errorDocumentUpload:button")}</Button>
      </ButtonContainer>
    </CommonProductPage>
  );
};
